import React, { Component } from 'react'
import { GoogleMap, KmlLayer, LoadScript } from '@react-google-maps/api'

const containerStyle = {
  width:'100%',
  height:'470px'
}
const center = {lat: -40.2108, lng: -73.03931}

class Map extends Component {
  generateRandom() { 
    return Math.random () * 10000000000000000 
  } 
  render() {
    return (
      
      <LoadScript
        googleMapsApiKey="AIzaSyCy9Kx4UtkxYNsMo09AhZPyDg9K6ktQUiA"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          mapTypeId = {"hybrid"} 
        >
          { /* Child components, such as markers, info windows, etc. */ }
          <KmlLayer 
            url = {`https://www.google.com/maps/d/u/0/kml?mid=1Su_Pe5iQWUUWM6ZIaS7fKCqL_NOi_uIF&ver=${this.generateRandom()}`}
            opciones = {{preserveViewport: true}} 
          /> 
        </GoogleMap>
      </LoadScript>
      
    )
  }
}

export default Map